import * as React from "react";
import { Spinner } from "react-bootstrap";
import {} from "react-bootstrap/esm/types";

export interface LoadingSpinnerProps {
  size?: "sm";
}

export const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => (
  <Spinner animation="border" role="status" variant="primary" size={props.size}>
    <span className="sr-only">Loading...</span>
  </Spinner>
);
