import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Footer.module.scss";

export const Footer = (): JSX.Element => (
  <div className={`${styles.footer} d-print-none`}>
    <Container>
      <Row>
        <Col md={7}>
          <p>
            &copy; {new Date().getFullYear()} Airlines Reporting Corporation
          </p>
          <p>
            SENSITIVE AND CONFIDENTIAL. Data presented or downloaded here may
            contain ARC-sensitive information. Disclosure to 3rd parties is
            prohibited without prior written consent of ARC.
          </p>
        </Col>
        <Col md={5}>
          <div className={styles.links}>
            <a
              className="fds-link"
              href="https://www2.arccorp.com/website-terms-of-use/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms Of Use
            </a>
            <a
              className="fds-link"
              href="https://www2.arccorp.com/site-privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              className="fds-link"
              href="https://www2.arccorp.com/about-us/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);
